import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//components
import PageHeading from "../../components/admin/PageHeading";
import Widget from "../../components/admin/Widget";
import ReservationPercentage from "../../components/admin/dashboard/ReservationPercentage";
import MostCreatedReservations from "../../components/admin/dashboard/MostCreatedReservations";
import LastBookings from "../../components/admin/dashboard/LastBookings";
import SalesReport from "../../components/admin/dashboard/SalesReport";
import Skeleton from "react-loading-skeleton";
import MostBookedHouses from "../../components/admin/dashboard/MostBookedHouses";

//icons
import { Euro } from "@styled-icons/boxicons-regular/Euro";
import { BookInformation as Book } from "@styled-icons/fluentui-system-filled/BookInformation";
import { Create as Pen } from "@styled-icons/material-outlined/Create";
import { Person } from "@styled-icons/bootstrap/Person";
import { PersonAddAlt as PersonAdd } from "@styled-icons/material/PersonAddAlt";
import { BarChart } from "@styled-icons/bootstrap/BarChart";
import { House } from "@styled-icons/bootstrap/House";

const Dashboard = () => {
  //dashboard data
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [reservationPercentage, setReservationPercentage] = useState([]);
  const [popularClients, setPopularClients] = useState([]);
  const [popularHouses, setPopularHouses] = useState([]);
  const [newestReservations, setNewestReservations] = useState([]);

  const updateWidgets = (data) => {
    const {
      current_week_earnings,
      total_earnings,
      upcoming_reservations,
      completed_reservations,
    } = data;

    const updatedWidgets = [
      {
        name: "Ienākumi šonedēļ",
        value: `€${current_week_earnings}`,
        Icon: Euro,
      },
      {
        name: "Ienākumi šajā sezonā",
        value: `€${total_earnings}`,
        Icon: Euro,
      },
      {
        name: "Rezervācijas (neapstiprinātās un aktīvās)",
        value: upcoming_reservations,
        Icon: Book,
      },
      {
        name: "Noslēgtās rezervācijas",
        value: completed_reservations,
        Icon: Book,
      },
    ];

    setWidgets(updatedWidgets);
  };

  const updateReservationPercentageWidget = (data) => {
    const { total_reservations, total_reservations_admin } = data;

    const adminPercentage = Math.round(
      (100 * total_reservations_admin) / total_reservations
    );
    const clientPercentage = Math.round(100 - adminPercentage);

    const updatedReservationPercentage = [
      { name: "Klienta izveidots", percentage: clientPercentage },
      { name: "Administratora izveidots", percentage: adminPercentage },
    ];

    setReservationPercentage(updatedReservationPercentage);
  };

  const getDashboardData = async () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/admin.dashboard.php`, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
          },
        })
        .then((response) => {
          const data = response.data;
          updateWidgets(data);
          updateReservationPercentageWidget(data);
          setPopularClients(data?.popular_clients);
          setPopularHouses(data?.popular_houses);
          setNewestReservations(data?.newest_reservations);

          setTimeout(() => {
            setLoading(false);
          }, [1000]);
        });
    } catch {
      console.error(`No response`);
    }
  };

  useEffect(() => {
    getDashboardData();
    //eslint-disable-next-line
  }, []);

  return (
    <Wrapper className="container-center">
      <PageHeading
        text={"Šeit ir redzama pārskatāma statistika par jūsu kempingu"}
      />

      <Content>
        <Widgets>
          {loading ? (
            <>
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton height={150} key={index} />
              ))}
            </>
          ) : (
            <>
              {widgets?.map((widget, i) => {
                const { name, value, Icon } = widget;
                return <Widget key={i} name={name} value={value} Icon={Icon} />;
              })}
            </>
          )}
        </Widgets>

        <LargeWidgets>
          <LargeWidgetsLeft>
            {loading ? (
              <Skeleton height={300} />
            ) : (
              <>
                <SalesReport name={"Atskaites"} Icon={BarChart} />
              </>
            )}

            {loading ? (
              <Skeleton height={300} />
            ) : (
              <>
                <LastBookings
                  name={"Pēdējās 5 rezervācijas"}
                  Icon={PersonAdd}
                  data={newestReservations}
                />
              </>
            )}
          </LargeWidgetsLeft>

          <LargeWidgetsRight>
            {/* How much customers are making the reservations widget */}
            {loading ? (
              <Skeleton height={150} />
            ) : (
              <>
                <ReservationPercentage
                  name={"Rezervācijas veidošana"}
                  Icon={Pen}
                  barData={reservationPercentage}
                />
              </>
            )}
            {/* The most active customers */}
            {loading ? (
              <>
                <Skeleton height={350} />
              </>
            ) : (
              <>
                <MostCreatedReservations
                  name={"Biežākie klienti"}
                  desc={"Populārākie klienti pēc rezervācijas vārda"}
                  Icon={Person}
                  data={popularClients}
                />
              </>
            )}

            {/* The most booked houses */}
            {loading ? (
              <>
                <Skeleton height={350} />
              </>
            ) : (
              <>
                <MostBookedHouses
                  name={"Populārākās mājiņas"}
                  desc={"Mājiņas, kuras tiek visvairāk rezervētas"}
                  Icon={House}
                  data={popularHouses}
                />
              </>
            )}
          </LargeWidgetsRight>
        </LargeWidgets>
      </Content>
    </Wrapper>
  );
};

const LargeWidgetsRight = styled.div`
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 760px) {
    max-width: unset;
  }
`;

const LargeWidgetsLeft = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
  gap: 16px;
`;

const LargeWidgets = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const Widgets = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin: 0 0 16px 0;

  @media (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
    overflow-x: scroll;
    padding: 10px;
  }
`;

const Content = styled.div`
  padding: 0 0 40px 0;
`;

const Wrapper = styled.main``;

export default Dashboard;
